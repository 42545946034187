import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout"
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { debounce } from 'lodash';
import * as XLSX from 'xlsx';
import BulkUpdateProduct from '../../components/BulkUpdateProduct';
import BulkUpdateProductByModel from '../../components/BulkUpdateProductByModel';
import BulkUpdateStock from '../../components/BulkUpdateStock';

const adminRole = localStorage.getItem('admin_role');

export default function Product() {

    const { setIsLoading } = useContext(LoadingContext);
    const [productList, setProductList] = useState([]);
    const [allPage, setAllPage] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [brandFilter, setBrandFilter] = useState("");
    const [categories, setCategories] = useState([]);
    const brands = [
        { id: 1, name: 'samsung' },
        { id: 2, name: 'lg' },
        { id: 3, name: 'oppo' },
        { id: 4, name: 'hitachi' },
        { id: 5, name: 'xiaomi' },
        { id: 6, name: 'lenovo' },
        { id: 7, name: 'acer' },
        { id: 8, name: 'asus' },
        { id: 9, name: 'HP' },
        { id: 10, name: 'Vivo' },
    ];
    const navigate = useNavigate();
    let page = useRef(1);

    useEffect(() => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/products/categories`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setCategories(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    const fetchProducts = useCallback((value = 1, search = '') => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/products/search-filter/${value}?keyword=${search}&category=${categoryFilter}&brand=${brandFilter}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.status === 500) {

            }
            if (response.data.status === 200) {
                setProductList(response.data.product);
                setIsLoading(false);
                setAllPage(response.data.all);
            }
        });
    }, [categoryFilter, brandFilter, setIsLoading]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);



    const fetchNextPage = (event, value) => {
        page.current = value;
        fetchProducts(value, keyword);
    }

    // eslint-disable-next-line
    const debouncedHandleSearchChange = useCallback(debounce((value) => {
        fetchProducts(1, value);
    }, 300), []);

    const handleSearchChange = (e) => {
        setKeyword(e.target.value);
        debouncedHandleSearchChange(e.target.value);
    }


    const addNumberFormat = (number) => {
        return parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    const [openDelete, setOpenDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleClickOpenDelete = (id) => {
        setDeleteId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setDeleteId(null);
        setOpenDelete(false);
    };

    const requestDelete = (id) => {
        setIsLoading(true)
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_BACKEND_URL}/product/${id}/delete`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            if (response.data.status === 500) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: response.data.msg
                });
            }
            if (response.data.status === 200) {
                fetchProducts(page.current);
                setAlert({
                    open: true,
                    type: 'success',
                    msg: 'ลบข้อมูลสำเร็จ'
                });
            }
        });
    }

    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };
    function exportToExcel(data) {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "exported_data.xlsx");
    }

    const handleExport = async () => {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/export-products`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        });

        if (response.status === 200) {
            exportToExcel(response.data.product);
        } else {
            console.log('Error');
        }

        setIsLoading(false);
    }


    return (
        <Layout mainClass='bg-gray-100'>
            <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 95px)' }}>
                <div className='flex flex-col lg:flex-row justify-between w-full items-start'>
                    <div className='flex flex-col lg:flex-row gap-4'>
                        {/*<h1 className='text-2xl font-bold hidden lg:block'></h1>*/}
                        <div className='relative'>
                            <input
                                type='text'
                                className='appearance-none block w-full py-2 pl-4 pr-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none focus:shadow-outline'
                                placeholder='ค้นหาสินค้า'
                                value={keyword}
                                onInput={handleSearchChange}
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                        </div>
                        <select
                            className='border border-gray-300 rounded-lg py-1.5 px-4 text-sm focus:outline-none focus:shadow-outline'
                            value={categoryFilter} onChange={(e) => { setCategoryFilter(e.target.value); }}
                        >
                            <option value="">All Categories</option>
                            {categories.map((category) => (
                                <optgroup label={category.name} key={category.slug}>
                                    <option value={category.slug}>{category.name}</option>
                                    {category.subcategories.map((subcategory) => (
                                        <option key={subcategory.slug} value={subcategory.slug}> -- {subcategory.name}</option>
                                    ))}
                                </optgroup>
                            ))}
                        </select>
                        <select
                            className='border border-gray-300 rounded-lg py-1.5 px-4 text-sm focus:outline-none focus:shadow-outline'
                            value={brandFilter} onChange={(e) => { setBrandFilter(e.target.value); }}
                        >
                            <option value="">All Brands</option>
                            {brands.map((brand) => (
                                <option key={brand.id} value={brand.id}>{brand.name}</option>
                            ))}
                        </select>
                    </div>
                    {adminRole === '1' || adminRole === '3' ? (
                        <>
                            <div className='flex gap-4 mt-4 lg:mt-0 items-start'>

                                <Button className='w-full' variant="contained" onClick={() => { navigate("/product/add"); setIsLoading(true) }}>+ เพิ่มสินค้า</Button>
                            </div>

                        </>
                    ) : ''}

                </div>

                {adminRole === '1' || adminRole === '3' ? (
                    <div className='flex gap-4 mt-4 px-2'>
                        <button className='underline rounded-md hidden lg:block' onClick={() => fetchProducts(page.current)}>Reload</button>
                        {/*<button className='bg-gray-200 px-4 rounded-md hidden lg:block' onClick={handleExport}>Export</button>*/}
                        <BulkUpdateProduct setAlert={setAlert} handleExport={handleExport} />
                        <BulkUpdateProductByModel setAlert={setAlert} categories={categories} />
                        <BulkUpdateStock setAlert={setAlert} categories={categories} />
                    </div>
                ) : ''}

                <div className='table-row-warp mt-8 flex-grow bg-white px-4 pt-2 rounded-t-xl overflow-x-auto'>
                    <div className='table-inner-wrap'>
                        <div className={`py-2 px-4 border-b w-full flex gap-4 table-row-head`}>
                            <div className='w-1/12  font-bold'>รูปสินค้า</div>
                            <div className='w-4/12  font-bold'>ชื่อสินค้า</div>
                            <div className='w-1/12  font-bold'>แบรนด์</div>
                            <div className='w-1/12  font-bold'>หมวดหมู่สินค้า</div>
                            <div className='w-1/12  font-bold'>Model Code</div>
                            <div className='w-1/12  font-bold'>Model Name</div>
                            <div className='w-2/12  font-bold text-right'>ราคา</div>
                            <div className='w-1/12  font-bold text-right'>จัดการ</div>
                        </div>
                        {productList.length > 0 && productList.map((product, index) => (

                            <div className={`table-row-tr py-2 px-4 border-b w-full flex items-center gap-4 text-sm ${index % 2 === 0 ? 'bg-gray-50/50' : ''}`} key={index}>

                                <div className='w-1/12 '>
                                    <img className='aspect-square' src={product.image} alt={product.name} width={50} height={50} />
                                </div>
                                <div className='w-4/12 line-clamp-2'>
                                    <Link to={`/product/edit/${product.id}`}>{product.name}</Link>
                                </div>
                                <div className='w-1/12 '>
                                    {product.brand}
                                </div>

                                <div className='w-1/12 '>{product.catName}</div>
                                <div className='w-1/12 truncate'>{product.modelCode}</div>
                                <div className='w-1/12 truncate'>{product.modelName}</div>

                                <div className='w-2/12 '>
                                    <div className='flex gap-2 items-end justify-end'>
                                        {product.price > 0.00 ? <p className={`product-price  ${product.sale > 0.00 ? 'line-through text-gray-500 text-sm' : 'font-bold text-md'}`}>{addNumberFormat(product.price)} ฿</p> : '-'}
                                        {product.sale > 0.00 ? <p className="product-price text-md font-bold text-red-600">{addNumberFormat(product.sale)} ฿</p> : ''}
                                    </div>
                                </div>
                                <div className='w-1/12 flex justify-end gap-2'>

                                    <Tooltip title="ดู / แก้ไข" placement="top" arrow >
                                        <IconButton aria-label="edit" sx={{ backgroundColor: '#f1f7fe' }} onClick={() => navigate(`/product/edit/${product.id}`)}>
                                            <ModeEditOutlineOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>

                                    {adminRole === '1' || adminRole === '3' ? (
                                        <Tooltip title="ลบ" placement="top" arrow>
                                            <IconButton aria-label="delete" onClick={() => handleClickOpenDelete(product.id)} sx={{ backgroundColor: '#f1f7fe' }}>
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : ''}

                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='w-full bg-white p-2 flex justify-between items-center'>
                    <div className='ml-4 hidden lg:block'>หน้าที่ {page.current} / {allPage}</div>
                    <Pagination count={allPage} page={page.current} onChange={fetchNextPage} />
                </div>
            </div>
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ยืนยันการลบข้อมูล
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        เมื่อลบข้อมูลแล้วจะไม่สามารถกู้คืนได้ คุณต้องการลบข้อมูลหรือไม่?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>ยกเลิก</Button>
                    <Button onClick={() => {
                        requestDelete(deleteId);
                        handleCloseDelete();
                    }} autoFocus>
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}