
import { useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AlertContext } from '../../contexts/AlertContext';
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout"
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import UploadImages from '../../components/UploadImages';
import ProductFilterList from '../../components/ProductFilterList';
import ProductModelSearch from '../../components/ProductModelSearch';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';

export default function AddNewProduct() {

    const { alert, setAlert } = useContext(AlertContext);
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [publicDate, setPublicDate] = useState(dayjs(new Date()).format('YYYY/MM/DD'));
    const [expDate, setExpDate] = useState(null);
    const [productParent, setProductParent] = useState(null);
    const [selectedItems, setSelectedItems] = useState({});
    const [productImage, setProductImage] = useState([]);
    //const [alert, setAlert] = useState({ open: false, message: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const [scrapingURL, setScrapingURL] = useState('');
    const [selectedScrapingProvider, setSelectedScrapingProvider] = useState(process.env.REACT_APP_HOMEPRO);

    const scrapingProvider = [
        { name: 'HomePro', url: process.env.REACT_APP_HOMEPRO },
        { name: 'BananaIT', url: process.env.REACT_APP_BANANA },
    ];

    const [productData, setProductData] = useState({
        title: '',
        description: '',
        shortDescription: '',
        productPrice: '',
        productSalePrice: '',
        modelCode: '',
        modelName: '',
        brand: 'LG',
        installment_6: '',
        installment_8: '',
        installment_10: '',
        installment_12: '',
        installment_18: '',
        installment_24: '',
        use_status: 1,
    });

    const brands = [
        'LG',
        'OPPO',
        'SAMSUNG',
        'HITACHI',
        'Xiaomi',
        'Lenovo',
        'Acer',
        'Asus',
        'HP',
        'Vivo'
    ];

    const handleSelectedItems = (filterName) => {
        setSelectedItems(filterName);
    };

    const onImagesChange = (newImage) => {
        setProductImage(newImage);
    }

    const publishProduct = () => {

        if (productData.title === '') {
            setAlert({ open: true, severity: 'error', message: 'กรุณากรอกชื่อสินค้า' });
            return;
        }

        if (!selectedItems.หมวดหมู่) {
            setAlert({ open: true, severity: 'error', message: 'กรุณาเลือกหมวดหมู่สินค้า' });
            return;
        }

        let error = false;
        if (selectedItems.หมวดหมู่) {
            Object.keys(selectedItems.หมวดหมู่).forEach((key) => {
                if (key === '6' && !selectedItems.ความจำ) {
                    error = true;
                }
                if (key === '6' && selectedItems.ความจำ?.length === 0) {
                    error = true;
                }
            });
        }

        if (error) {
            setAlert({ open: true, severity: 'error', message: 'กรุณาเลือกความจำสินค้า' });
            return;
        }

        setIsLoading(true);

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/product/addnew`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                publicDate: publicDate,
                expDate: expDate,
                productParent: productParent,
                trems: selectedItems,
                gallery: productImage,
                productData: productData
            }
        }).then(function (response) {
            if (response.data.status === 500) {
                setAlert({ open: true, severity: 'error', message: response.data.msg });
            } else if (response.data.status === 200) {
                setAlert({ open: true, severity: 'success', message: 'เพิ่มสินค้าสำเร็จ' });
                navigate(`/product/edit/${response.data.id}`)
            }
        }).catch(function (error) {
            setAlert({ open: true, severity: 'error', message: 'มีข้อผิดพลาดในการเพิ่มสินค้า' });
        }).finally(function () {
            setIsLoading(false);
        });
    }

    const handleAlertClose = () => {
        setAlert({ open: false, severity: 'error', message: '' });
    };

    function readFileAsDataURL(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = event => {
                resolve(event.target.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    const uploadMediatoServer = (name, url) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/images/upload`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                name: name,
                url: url
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                // add to product gallery
                const newImage = {
                    id: response.data.images.id,
                    url: response.data.images.image,
                    fromServer: true,
                    name: response.data.images.name
                };

                setProductImage(prev => [...prev, newImage]);
            } else {
                setAlert({ open: true, severity: 'error', message: 'มีข้อผิดพลาดในการอัพโหลดรูปภาพ' });
            }
        });
    }

    const handleScraping = () => {
        if (scrapingURL === '') {
            setAlert({ open: true, severity: 'error', message: 'กรุณาใส่ URL ที่ต้องการ Scraping' });
            return;
        }

        setIsLoading(true);

        axios({
            method: 'get',
            url: `${selectedScrapingProvider}/scrape?url=${encodeURIComponent(scrapingURL)}`,
        }).then(async function (response) {
            setProductData({
                ...productData,
                title: response.data.Title,
                shortDescription: response.data.ShortDescription,
                description: response.data.LongDescription,
            });

            for (const imageUrl of response.data.ImageGallery) {
                try {
                    const imageResponse = await axios(imageUrl, { responseType: 'blob' });
                    const file = new File([imageResponse.data], 'image.jpg', { type: 'image/jpeg' });
                    const base64Image = await readFileAsDataURL(file);
                    const imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.jpg';
                    uploadMediatoServer(imageName, base64Image);
                } catch (error) {
                    console.error('Failed to upload image', error);
                }
            }

        }).catch(function (error) {
            setAlert({ open: true, severity: 'error', message: 'หมดเวลาเชื่อมต่อ กรุณาลองใหม่อีกครั้ง' });
        }).finally(function () {
            setIsLoading(false);
            setOpenDialog(false);
            setScrapingURL('');
        });
    }


    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex justify-between w-full mb-8'>
                <h1 className='text-2xl font-bold'><Link to='/product'> <ArrowBackOutlinedIcon /></Link> เพิ่มสินค้าใหม่</h1>
                <button
                    className='bg-gray-200 px-4 py-2'
                    onClick={() => setOpenDialog(true)}
                >
                    ดึงข้อมูลสินค้าจาก URL
                </button>
            </div>
            <div className='grid grid-cols-4 gap-4 items-start'>
                <div className='col-span-3 sticky top-10'>
                    <div className='p-8 bg-white mb-4'>
                        <input
                            type='text'
                            name='product_title'
                            placeholder='ชื่อสินค้า'
                            className='text-2xl font-bold border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                            value={productData.title}
                            onChange={(e) => setProductData(
                                prev => ({
                                    ...prev,
                                    title: e.target.value
                                })
                            )}
                        />

                        <div className="myEditor">

                            <CKEditor
                                editor={Editor}
                                data={productData.description}
                                onReady={() => {
                                    setIsLoading(false);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setProductData(prev => ({
                                        ...prev,
                                        description: data
                                    }))
                                }}
                            />

                        </div>
                    </div>

                    <div className='p-8 bg-white mb-4'>
                        <div className='grid grid-cols-2 gap-4'>
                            <div>
                                <label className='font-bold mb-2 block text-lg'>ราคาปกติ (฿)</label>
                                <input
                                    type='number'
                                    placeholder='ราคาปกติ'
                                    className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                                    value={productData.productPrice}
                                    onChange={(e) => setProductData(
                                        prev => ({
                                            ...prev,
                                            productPrice: e.target.value
                                        })
                                    )}
                                />
                            </div>
                            <div>
                                <label className='font-bold mb-2 block text-lg'>ราคาที่ลด (฿)</label>
                                <input
                                    type='number'
                                    placeholder='ราคาที่ลด (฿)'
                                    className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                                    value={productData.productSalePrice}
                                    onChange={(e) => setProductData(
                                        prev => ({
                                            ...prev,
                                            productSalePrice: e.target.value
                                        })
                                    )}
                                />
                            </div>


                            <div className='col-span-2'>
                                <label className='font-bold block text-lg -mb-3'>การผ่อนชำระ</label>
                            </div>
                            <div className='col-span-2 grid grid-cols-2 gap-x-16 p-8 border border-gray-300'>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>6 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='6 งวด'
                                        className=' text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_6 ? productData.installment_6 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_6: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>8 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='8 งวด'
                                        className=' text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_8 ? productData.installment_8 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_8: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>10 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='10 งวด'
                                        className='text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_10 ? productData.installment_10 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_10: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>12 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='12 งวด'
                                        className='text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_12 ? productData.installment_12 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_12: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>18 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='18 งวด'
                                        className='text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_18 ? productData.installment_18 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_18: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                                <div className='flex border-b justify-between mb-4 items-center px-4 hover:bg-slate-50'>
                                    <label className='font-bold block whitespace-nowrap'>24 งวด</label>
                                    <input
                                        type='number'
                                        placeholder='24 งวด'
                                        className='text-gray-900 text-right bg-transparent block p-1 w-full appearance-none focus:outline-none focus:shadow-outline'
                                        value={productData.installment_24 ? productData.installment_24 : ''}
                                        onChange={(e) => setProductData(
                                            prev => ({
                                                ...prev,
                                                installment_24: e.target.value
                                            })
                                        )}
                                    />
                                    <div>บาท</div>
                                </div>
                            </div>

                            <div>
                                <label className='font-bold mb-2 block text-lg'>Model code</label>
                                <input
                                    type='text'
                                    placeholder='Model code'
                                    className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                                    value={productData.modelCode}
                                    onChange={(e) => setProductData(
                                        prev => ({
                                            ...prev,
                                            modelCode: e.target.value
                                        })
                                    )}
                                />
                            </div>
                            <div>
                                <label className='font-bold mb-2 block text-lg'>Model name</label>
                                <input
                                    type='text'
                                    placeholder='Model name'
                                    className='border border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 px-4 mb-4 appearance-none focus:outline-none focus:shadow-outline'
                                    value={productData.modelName}
                                    onChange={(e) => setProductData(
                                        prev => ({
                                            ...prev,
                                            modelName: e.target.value
                                        })
                                    )}
                                />
                            </div>
                            <div className='col-span-2'>
                                <label className='font-bold mb-2 block text-lg'>สินค้าที่เชื่อมโยง</label>
                                <ProductModelSearch onModelSelected={setProductParent} />
                            </div>
                        </div>


                    </div>


                    <div className='p-8 bg-white mb-4'>
                        <label className='font-bold mb-2 block text-lg'>รูปสินค้า</label>
                        <UploadImages
                            onImagesChange={onImagesChange}
                            defImages={productImage}
                        />
                    </div>

                    <div className='p-8 bg-white mb-4'>
                        <label className='font-bold mb-2 block text-lg'>คำอธิบายสินค้าอย่างสั้น</label>
                        <div className="myEditor">
                            <CKEditor
                                editor={Editor}
                                data={productData.shortDescription}
                                onReady={() => {
                                    setIsLoading(false);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setProductData(prev => ({
                                        ...prev,
                                        shortDescription: data
                                    }))
                                }}
                            />

                        </div>
                    </div>

                </div>

                <div className='col-span-1'>
                    <div className='p-8 bg-white mb-4'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className='mb-4'>
                                <DatePicker
                                    renderInput={(props) =>
                                        <TextField
                                            {...props}
                                            variant="standard"
                                            fullWidth
                                        />
                                    }
                                    ampm={false}
                                    label="วันที่เผยแพร่สินค้า"
                                    inputFormat="YYYY/MM/DD"
                                    value={publicDate || dayjs(new Date()).format('YYYY/MM/DD')}
                                    onChange={(newValue) => {
                                        if (newValue !== null && newValue.$d !== 'Invalid Date') {
                                            setPublicDate(dayjs(newValue.$d).format('YYYY/MM/DD'));
                                        } else {
                                            setPublicDate(dayjs(new Date()).format('YYYY/MM/DD'));
                                        }
                                    }}
                                />
                            </div>

                            <div className='relative mb-4'>
                                <DatePicker
                                    renderInput={(props) =>
                                        <TextField
                                            {...props}
                                            variant="standard"
                                            fullWidth
                                            value={expDate ? dayjs(expDate).format('YYYY/MM/DD') : "ไม่กำหนด"}
                                        />
                                    }
                                    ampm={false}
                                    label="แสดงสินค้าจนถึงวันที่"
                                    inputFormat="YYYY/MM/DD"
                                    value={expDate}
                                    onChange={(newValue) => {
                                        if (newValue !== null && dayjs(newValue).isValid()) {
                                            setExpDate(newValue);
                                        } else {
                                            setExpDate(null);
                                        }
                                    }}
                                />
                            </div>
                        </LocalizationProvider>

                        <label className='font-bold mb-2 block'>สถานะสินค้า</label>
                        <div className='flex items-center mb-4'>
                            <Switch
                                checked={productData.use_status === 1 ? true : false}
                                onChange={(e) => setProductData(
                                    prev => ({
                                        ...prev,
                                        use_status: e.target.checked ? 1 : 0
                                    })
                                )}
                            />
                            <span>{productData.use_status ? 'ใช้งาน' : 'ไม่ใช้งาน'}</span>
                        </div>

                        <button
                            className='w-full bg-indigo-800 px-4 py-2 text-white'
                            disabled={isLoading}
                            onClick={() => publishProduct()}
                        >
                            เผยแพร่สินค้า
                        </button>
                    </div>

                    <div className='p-8 bg-white mb-4'>
                        <label className='font-bold mb-2 block text-lg'>แบรนด์สินค้า</label>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={productData.brand}
                            className='w-full'
                            onChange={(e) => setProductData(
                                prev => ({
                                    ...prev,
                                    brand: e.target.value
                                })
                            )}
                        >
                            {brands.map((item, index) => (
                                <MenuItem value={item} key={index}>{item}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <ProductFilterList onItemsSelected={handleSelectedItems} />
                </div>

            </div>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                width='lg'
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{"Scraping URL"}</DialogTitle>
                <DialogContent>

                    <Select
                        value={selectedScrapingProvider}
                        className='w-full'
                        onChange={(e) => setSelectedScrapingProvider(e.target.value)}
                    >
                        {scrapingProvider.map((item, index) => (
                            <MenuItem value={item.url} key={index}>{item.name}</MenuItem>
                        ))}
                    </Select>


                    <div className='text-sm mt-4 pl-2'>
                        กรุณาใส่ URL ที่ต้องการ Scraping <br />เช่น
                        {selectedScrapingProvider === process.env.REACT_APP_HOMEPRO
                            ? 'https://www.homepro.co.th/p/1212740' :
                            'https://www.bnn.in.th/th/p/....dl6p2x'
                        }
                    </div>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="URL"
                        type="text"
                        fullWidth
                        value={scrapingURL}
                        onChange={(e) => setScrapingURL(e.target.value)}
                        placeholder={selectedScrapingProvider === process.env.REACT_APP_HOMEPRO
                            ? 'https://www.homepro.co.th/p/1212740' :
                            'https://www.bnn.in.th/th/p/....dl6p2x'
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <button onClick={() => setOpenDialog(false)} className='bg-gray-200 px-4 py-2 rounded-md'>ยกเลิก</button>
                    <button
                        onClick={() => {
                            handleScraping();
                        }}
                        className='bg-indigo-800 px-4 py-2 text-white rounded-md'
                        disabled={isLoading}
                    >
                        Scraping
                    </button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
}