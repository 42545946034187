import { useEffect, useState, useContext } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import LoadingContext from '../../contexts/LoadingContext';
import Layout from "../../layouts/layout";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import TextareaAutosize from '@mui/base/TextareaAutosize';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Autocomplete, TextField } from '@mui/material';
import OrderMessage from '../../components/OrderMessage';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ApproveProducts from '../../components/ApproveProducts';

export default function EditOrdersOther() {

    const { company, id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [orderData, setOrderData] = useState(null);
    //const [logsData, setLogsData] = useState([]); // [ { id: 1, log_text: 'test', last_update: '2021-10-10 10:10:10' } ]
    const [orderStatus, setOrderStatus] = useState(8);
    const [adminNote, setAdminNote] = useState('');
    const [orderProducts, setOrderProducts] = useState([]); // [ { id: 1, product_name: 'test', product_price: 100, product_quantity: 1 }
    const [orderTotal, setOrderTotal] = useState(0);
    const [whyNotApprove, setWhyNotApprove] = useState(1);
    const [allAdmin, setAllAdmin] = useState([]);
    const [assignTo, setAssignTo] = useState(1);
    // const [contractDate, setContractDate] = useState(null);
    // const [contractNumber, setContractNumber] = useState('');
    /*
     const [errors, setErrors] = useState({
         dateOfMeeting: null,
         contractNumber: null,
     });
     */

    const [approveDetail, setApproveDetail] = useState(null);
    const [approveDetailError, setApproveDetailError] = useState([]);

    const handleOrderStatusChange = (event) => {
        setOrderStatus(event.target.value);
        if (event.target.value === 12) {
            setWhyNotApprove(1)
        }
    };

    const convertWebsiteTotable = (website) => {
        switch (website) {
            case 'gse-stores.com':
                return 'web_order';
            case 'mcapital.co.th':
                return 'mcapital_orders';
            case 'gseinsurebroker.com':
                return 'gseinsure_orders';
            default:
                return 'ไม่ระบุ';
        }
    }

    useEffect(() => {
        setIsLoading(true)
        window.scrollTo(0, 0);
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/order/single-company/${id}/${convertWebsiteTotable(company)}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
        }).then(function (response) {
            setIsLoading(false);
            setOrderData(response.data.orderDetail);
            setAssignTo(response.data.orderDetail.assign_to);
            setWhyNotApprove(response.data.orderDetail.why_not_approve);
            //setLogsData(response.data.logs);
            setOrderStatus(response.data.orderDetail.order_status);
            setOrderTotal(response.data.orderDetail.order_total);
            setOrderProducts(response.data.orderProducts);
            let adminOrderNote = response.data.orderDetail.order_admin_remark;
            if (adminOrderNote === null) {
                adminOrderNote = '';
            }
            setAdminNote(adminOrderNote);
            //setContractDate(response.data.orderDetail.contract_date);
            //setContractNumber(response.data.orderDetail.contract_number ? response.data.orderDetail.contract_number : '');


            if (response.data.approveProduct?.length > 0) {
                let newApproveDetail = [];
                response.data.approveProduct.forEach((item, index) => {
                    newApproveDetail.push({
                        contractDate: item.contract_date ? dayjs(item.contract_date) : null,
                        contractNumber: item.contract_number ? item.contract_number : '',
                        productId: item.product_id ? item.product_id : null,
                        productName: item.product_name ? item.product_name : '',
                        productQty: item.product_qty ? item.product_qty : 1,
                        unitPrice: item.unit_price ? item.unit_price : 0,
                        totalPrice: item.total_price ? item.total_price : 0,
                    });
                });
                setApproveDetail(newApproveDetail);
            }


        }).catch(function (error) {
            window.location.href = '/order';
            console.log(error);
        });
    }, [id, setIsLoading, company]);

    useEffect(() => {
        if (localStorage.getItem('admin_role') === '1') {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/admin/fetch-all`,
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('admin_token')}`,
                },
            }).then(function (response) {
                if (response.data.status === 200) {
                    setAllAdmin(prevAllAdmin => [
                        ...prevAllAdmin,
                        ...response.data.data
                    ]);
                }
            }).catch(function (error) {
                setAlert({
                    open: true,
                    type: 'error',
                    msg: 'ไม่สามารถเรียกข้อมูล Admin ได้'
                });
            });
        }
    }, []);


    const statusOption = [
        {
            value: 8,
            label: 'รอการติดต่อกลับภายใน 24 ชั่วโมง',
            color: 'text-gray-800',
            bg: 'bg-gray-200',
        },
        {
            value: 10,
            label: 'กำลังดำเนินการ/รอการพิจารณา',
            color: 'text-white',
            bg: 'bg-amber-500',
        },
        {
            value: 11,
            label: 'อนุมัติ',
            color: 'text-white',
            bg: 'bg-indigo-500',
        },
        {
            value: 12,
            label: 'ไม่อนุมัติ',
            color: 'text-white',
            bg: 'bg-red-600',
        },
        {
            value: 9,
            label: 'ดำเนินการเสร็จสิ้น',
            color: 'text-white',
            bg: 'bg-green-500',
        },
    ];

    const whyNotApproveOption = [
        {
            value: 1,
            label: 'ติด NPL',
            color: 'text-gray-800',
            bg: 'bg-gray-100',
        },
        {
            value: 2,
            label: 'สภาพการเดินบัญชีไม่ปกติ',
            color: 'text-white',
            bg: 'bg-red-600',
        },
        {
            value: 3,
            label: 'เป็นลูกค้าปัจจุบัน',
            color: 'text-white',
            bg: 'bg-red-600',
        },
    ];

    const convertToPill = (text, color, bg) => {
        return <span className={`px-4 py-1 text-xs whitespace-nowrap rounded-full ${color} ${bg}`}>{text}</span>
    }

    const [alert, setAlert] = useState({
        open: false,
        type: 'error',
        msg: '',
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({
            open: false,
            type: 'error',
            msg: ''
        });
    };

    const addNumberFormat = (number) => {
        return parseFloat(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    const validateOrderProduct = () => {
        let isValid = true;
        orderProducts.forEach((product) => {
            if (product.product_name === '') {
                isValid = false;
            }
            if (product.product_price === '') {
                isValid = false;
            }
            if (product.product_quantity === '') {
                isValid = false;
            }
        });
        return isValid;
    }

    const handleUpdateOrder = () => {

        /*
        if (contractDate !== null && !dayjs(contractDate).isValid()) {
            setErrors({ ...errors, dateOfMeeting: 'วันที่ไม่ถูกต้อง' });
            setAlert({
                open: true,
                type: 'error',
                msg: 'วันที่ไม่ถูกต้อง'
            });
            return;
        }
            */

        if (approveDetail !== null && approveDetail.length > 0) {
            const errors = approveDetail.map((item, index) => {
                const itemErrors = {};

                if (item.contractDate === null || !dayjs(item.contractDate).isValid()) {
                    itemErrors.contractDate = "Invalid date";
                }
                if (item.contractNumber === '' || item.contractNumber === null) {
                    itemErrors.contractNumber = "Contract number is required";
                }
                if (item.productName === '' || item.productName === null) {
                    itemErrors.productName = "Product name is required";
                }
                if (item.productQty === null || parseInt(item.productQty) <= 0 || item.productQty === '') {
                    itemErrors.productQty = "Invalid product quantity";
                }
                if (item.unitPrice === null || parseFloat(item.unitPrice) <= 0 || item.unitPrice === '') {
                    itemErrors.unitPrice = "Invalid unit price";
                }

                // Always return an object with the index, even if there are no errors
                return { index, errors: Object.keys(itemErrors).length > 0 ? itemErrors : null };
            });

            setApproveDetailError(errors.map(error => error.errors));

            // Check if there are any errors
            const hasErrors = errors.some(error => error.errors !== null);
            if (hasErrors) {
                return;
            }
        }


        if (!validateOrderProduct()) {
            setAlert({
                open: true,
                type: 'error',
                msg: 'กรุณากรอกข้อมูลสินค้าให้ครบถ้วน'
            });
            return;
        }

        setIsLoading(true);
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_BACKEND_URL}/order/single-company/update/${id}/${convertWebsiteTotable(company)}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
            },
            data: {
                order_status: orderStatus,
                order_total: orderTotal,
                order_products: orderProducts,
                order_admin_remark: adminNote,
                order_why_not_approve: whyNotApprove,
                assign_to: assignTo,
                //contract_date: contractDate !== null ? dayjs(contractDate).format('YYYY-MM-DD') : null,
                //contract_number: contractNumber,
                approveDetail: approveDetail,
            }
        }).then(function (response) {
            setIsLoading(false);
            setAlert({
                open: true,
                type: 'success',
                msg: 'แก้ไขสถานะคำสั่งซื้อสำเร็จ'
            });
        }).catch(function (error) {
            setIsLoading(false);
            setAlert({
                open: true,
                type: 'error',
                msg: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
            });
        });
    }

    const handleDeleteProduct = (id) => {
        setOrderProducts(orderProducts.filter((product) => product.id !== id));
    }

    const handleWhyNotApproveChange = (value) => {
        setWhyNotApprove(value);
    }

    const handleBackbuttonClick = () => {
        const params = new URLSearchParams(location.search);
        if (params.get('back')) {
            navigate(params.get('back'));
        } else {
            navigate('/order');
        }
    }

    const onAppreveProductChange = (data) => {
        // console.log(data);
        setApproveDetail(data);
    };

    return (
        <Layout mainClass='bg-gray-100 min-h-screen'>
            <div className='flex flex-col lg:flex-row items-center gap-4 w-full mb-8'>
                <h1 className='text-2xl font-bold flex gap-2 items-center'>
                    <button
                        onClick={() => handleBackbuttonClick()
                        }>
                        <ArrowBackOutlinedIcon />
                    </button>
                    แก้ไขคำสั่งซื้อ #{id.padStart(5, '0')}
                </h1>
                <span className='px-2'> ({company}) </span>
                {convertToPill(
                    statusOption.find((element) => element.value === orderStatus).label,
                    statusOption.find((element) => element.value === orderStatus).color,
                    statusOption.find((element) => element.value === orderStatus).bg,
                )}
            </div>

            {orderData !== null ?
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 items-start'>
                    <div className='col-span-3'>
                        <div className='p-8 bg-white mb-4'>
                            <h3 className='text-xl font-semibold mb-4'>ข้อมูลการสั่งซื้อ</h3>
                            <div className='grid grid-cols-2 lg:grid-cols-4 gap-4'>
                                <div>
                                    <label className='text-gray-500 text-xs'>ลูกค้า:</label>
                                    <p className='text-md'>{orderData.c_name} {orderData.c_lastname}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>อีเมล:</label>
                                    <p className='text-md'>{orderData.c_email}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>วัน - เวลา:</label>
                                    <p className='text-md'>{
                                        new Date(orderData.create_at).toLocaleDateString('th-TH', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        })
                                    }</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>ยอดรวม:</label>
                                    <p className='text-md'>{addNumberFormat(orderTotal)} บาท</p>
                                </div>

                                <div>
                                    <label className='text-gray-500 text-xs'>เบอร์โทร</label>
                                    <p className='text-md'>{orderData.c_phone}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>อาชีพ</label>
                                    <p className='text-md'>{orderData.c_company}</p>
                                </div>
                                <div>
                                    <label className='text-gray-500 text-xs'>ที่อยู่</label>
                                    <p className='text-md'>
                                        {orderData.c_address}
                                        <br />
                                        แขวง{orderData.c_sub_district} เขต{orderData.c_district}
                                        <br />
                                        {orderData.c_province} {orderData.c_post_code}
                                    </p>
                                </div>
                                <div></div>
                                {orderData.c_make && (
                                    <div>
                                        <label className='text-gray-500 text-xs'>รุ่นรถยนต์</label>
                                        <p className='text-md'>
                                            {orderData.c_make} {orderData.c_model} {orderData.c_year}
                                        </p>
                                    </div>
                                )}
                                {
                                    orderData.c_sub_model && (
                                        <div>
                                            <label className='text-gray-500 text-xs'>รุ่นย่อย</label>
                                            <p className='text-md'>{orderData.c_sub_model}</p>
                                        </div>
                                    )
                                }
                                {orderData.insurance_type && (
                                    <div>
                                        <label className='text-gray-500 text-xs'>ประเภทประกัน</label>
                                        <p className='text-md'>{orderData.insurance_type}</p>
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className='p-8 bg-white mb-4'>
                            <h3 className='text-xl font-semibold mb-4'>รายการสินค้า / บริการ</h3>
                            <div className='overflow-x-auto'>
                                <table className='table-auto w-full'>
                                    <thead>
                                        <tr className='bg-gray-200 text-gray-600 text-sm leading-normal'>
                                            <th className='py-2 px-4'>#</th>
                                            <th className='py-2 px-4'>ชื่อสินค้า</th>
                                            <th className='py-2 px-4'>ราคาต่อหน่วย</th>
                                            <th className='py-2 px-4'>จำนวน</th>
                                            <th className='py-2 px-4 text-right'>ราคารวม</th>
                                            <th className='py-2 px-4 text-right'>ลบ</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-gray-600 text-sm font-light'>
                                        {orderProducts.map((product, index) => (
                                            <tr
                                                className='border-b border-gray-200 hover:bg-gray-100'
                                                key={index}
                                                style={{ transition: 'all .15s ease' }}
                                            >
                                                <td className='py-2 px-4'>{index + 1}</td>
                                                <td className='py-2 px-4'>
                                                    <input
                                                        type='text'
                                                        className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none'
                                                        placeholder="ชื่อสินค้า / บริการ"
                                                        value={product.product_name}
                                                        onChange={(e) => {
                                                            let newOrderProducts = [...orderProducts];
                                                            newOrderProducts[index].product_name = e.target.value;
                                                            setOrderProducts(newOrderProducts);
                                                        }}
                                                    />
                                                </td>
                                                <td className='py-2 px-4'>
                                                    <input
                                                        type='number'
                                                        className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none'
                                                        placeholder="ราคาต่อหน่วย"
                                                        value={product.product_price}
                                                        onChange={(e) => {
                                                            let newOrderProducts = [...orderProducts];
                                                            newOrderProducts[index].product_price = e.target.value;
                                                            setOrderProducts(newOrderProducts);
                                                            setOrderTotal(newOrderProducts.reduce((total, product) => total + (product.product_price * product.product_quantity), 0));
                                                        }}
                                                    />
                                                </td>
                                                <td className='py-2 px-4'>
                                                    <input
                                                        type='number'
                                                        className='w-full p-2.5 border border-gray-300 focus:indigo-800 focus:border-indigo-800 focus:ring-0 outline-none'
                                                        placeholder="จำนวน"
                                                        value={product.product_quantity}
                                                        onChange={(e) => {
                                                            let newOrderProducts = [...orderProducts];
                                                            newOrderProducts[index].product_quantity = e.target.value;
                                                            setOrderProducts(newOrderProducts);
                                                            setOrderTotal(newOrderProducts.reduce((total, product) => total + (product.product_price * product.product_quantity), 0));
                                                        }}
                                                    />
                                                </td>
                                                <td className='py-2 px-4 text-right'>
                                                    {addNumberFormat(product.product_price * product.product_quantity)} บาท
                                                </td>
                                                <td className='py-2 px-4 text-right'>
                                                    <button
                                                        className='font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline'
                                                        type='button'
                                                        onClick={() => handleDeleteProduct(product.id)}
                                                    >
                                                        <CloseOutlinedIcon />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='text-right mt-4'>
                                    <button
                                        className='bg-indigo-800 px-4 py-2 text-white mt-2'
                                        onClick={() => {
                                            setOrderProducts([...orderProducts, { id: orderProducts.length + 1, product_name: '', product_price: 0.00, product_quantity: 1 }]);
                                        }}
                                    >
                                        เพิ่มสินค้า / บริการ
                                    </button>
                                </div>
                            </div>
                        </div>

                        {orderStatus === 11 &&
                            <ApproveProducts
                                allowSearch={true}
                                onInputChange={onAppreveProductChange}
                                approveDetailError={approveDetailError}
                                setApproveDetailError={setApproveDetailError}
                                initiateData={approveDetail}
                            />
                        }


                    </div>
                    <div className='col-span-1 sticky top-10'>
                        <div className='p-8 bg-white mb-4'>

                            <h3 className='text-xl font-semibold mb-4'>สถานะคำสั่งซื้อ</h3>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <Select
                                        value={orderStatus}
                                        onChange={handleOrderStatusChange}
                                        disabled={isLoading}
                                        variant="standard"
                                    >
                                        {statusOption.map((key, index) => (
                                            <MenuItem key={index} value={key.value}>{key.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            {orderStatus === 12 &&
                                <Box sx={{ minWidth: 120, mt: 2 }}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={whyNotApprove}
                                            onChange={(e) => handleWhyNotApproveChange(e.target.value)}
                                            disabled={isLoading}
                                            variant="standard"
                                        >
                                            {whyNotApproveOption.map((key, index) => (
                                                <MenuItem key={index} value={key.value}>{key.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            }
                            <button
                                className='mt-4 w-full bg-indigo-800 px-4 py-2 text-white'
                                disabled={isLoading}
                                onClick={() => handleUpdateOrder()}
                            >
                                บันทึกข้อมูล
                            </button>
                        </div>
                        {allAdmin.length > 0 && localStorage.getItem('admin_role') === '1' &&
                            <div className='p-8 bg-white mb-4'>
                                <h3 className='text-xl font-semibold mb-4'>สาขาที่ดูแล</h3>

                                <Autocomplete
                                    sx={{ width: '100%' }}
                                    options={allAdmin || []}
                                    getOptionLabel={(option) => option.admin_name}
                                    renderInput={(params) => <TextField {...params} label="เลือกสาขาที่ต้องการมอบหมาย" variant="outlined" />}
                                    onChange={(event, newValue) => {
                                        setAssignTo(newValue ? newValue.id : null);
                                    }}
                                    value={allAdmin.find((item) => item.id === assignTo) || null}
                                />


                                {/*<FormControl fullWidth>
                                    <Select
                                        value={assignTo}
                                        onChange={(e) => setAssignTo(e.target.value)}
                                        disabled={isLoading}
                                        variant="standard"
                                    >
                                        <MenuItem value={1}>ไม่ระบุ</MenuItem>
                                        {allAdmin.map((key, index) => (
                                            <MenuItem key={index} value={key.id}>{key.admin_name}</MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>*/}
                            </div>
                        }

                        <OrderMessage
                            orderId={id}
                            orderTable={convertWebsiteTotable(company)}
                            setAlert={setAlert}
                        />
                    </div>
                </div>
                : ''}
            <Snackbar className='z-50' open={alert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.msg}
                </Alert>
            </Snackbar>
        </Layout>
    )
}